import React, { useEffect, useRef, useState } from 'react';
import styles from './billing.module.scss';
import BillingTable from './billingTable';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { api } from '../../api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import CustomCheckbox from '../../components/CustomCheckBox/CustomCheckBox';
import Button from '../../components/Button';
import searchIcon from "../../assets/icons/search.svg";

const Billings = () => {
  const initialStartDate = new Date().toISOString().split("T")[0];
  const initialFinishDate = new Date().toISOString().split("T")[0];
  const [startDate, setStartDate] = useState(initialStartDate);
  const [finishDate, setFinishDate] = useState(initialFinishDate);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showProviderDropdown, setShowProviderDropdown] = useState(false);
  const [providers, setProviders] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedProvider, setSelectedProvider] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const practiceId =
    user?.practice && user.practice.length > 0 ? user.practice[0].id : null;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [shouldFilter, setFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  useEffect(() => {
    if (showDropdown) {
      fetchProviders(currentPage);
    }
  }, [showDropdown, currentPage]);

  const handleFilter = () => {
    setFilter(!shouldFilter);
  };

  const handleClear = () => {
    setStartDate(initialStartDate);
    setFinishDate(initialFinishDate);
    setSelectedProvider(null);
    setFilter(!shouldFilter);
  };

  const fetchProviders = async (page) => {
    try {
      const response = await api.get(`/practices/providers`, {
        params: {
          practiceId,
          page,
          limit: 5,
        },
      });
      setProviders(response?.data?.data?.practitioners);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching providers:", error);
    }
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleProviderSelect = (providerId) => {
    setSelectedProvider((prevSelectedProvider) =>
      prevSelectedProvider === providerId ? null : providerId
    );
  };

  const handleDownloadCsv = async () => {
    setLoading(true);
    try {
      const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
      const formattedFinishDate = moment(finishDate).format("YYYY-MM-DD");
      const response = await api.get(`/practices/transactions/download`, {
        params: {
          page: currentPage,
          limit: 20,
          startDate: formattedStartDate,
          endDate: formattedFinishDate,
          practiceId,
          practitionerId: selectedProvider,
        },
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "transactions.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoading(false);
      toast.success("Downloaded successfully");
    } catch (error) {
      console.log("error", error);
      toast.error("Error downloading transactions", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className={styles.root}>
      <h1 className={styles.header}>Invoice & Claims</h1>
      <div className={styles.options}>
        <div className={styles.filterContainer}>
          <div className={styles.filterToggle}>
            <div className="searchContainer">
              <img src={searchIcon} alt="" />
              <input
                type="search"
                placeholder="Search Patient"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
            {/* <div className={styles.filterBtn}>
              <button onClick={handleDropdownToggle}>Filter</button>
            </div> */}
            <div>
              <Button
                label="Download Transactions"
                onClick={handleDownloadCsv}
                isLoading={loading}
              />
            </div>
          </div>
          {/* {showDropdown && (
            <div ref={dropdownRef} className={styles.filterDropdown}>
              <h1>Filter By Date of Billing</h1>
              <div className={styles.date}>
                <span>Start Date</span>
                <DatePicker onChange={setStartDate} value={startDate} />
              </div>
              <div className={styles.date}>
                <span>Finish Date</span>
                <DatePicker onChange={setFinishDate} value={finishDate} />
              </div>
              <div>
                <h1>Select Provider</h1>
                <ul className={styles.providerList}>
                  {providers.map((provider) => (
                    <li key={provider._id} className={styles.providerItem}>
                      <div
                        className={styles.providerDetails}
                        onClick={() => handleProviderSelect(provider._id)}
                      >
                        {provider.firstName} {provider.lastName}
                      </div>
                      <CustomCheckbox
                        checked={selectedProvider === provider._id}
                        onChange={() => handleProviderSelect(provider._id)}
                      />
                    </li>
                  ))}
                </ul>
                <div className={styles.pagination}>
                  <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
              <div className={styles.filterButtons}>
                <button onClick={handleClear}>Clear</button>
                <button onClick={handleFilter}>Filter</button>
              </div>
            </div>
          )} */}
        </div>
      </div>

      <BillingTable
        searchInput={searchInput}
        startDate={startDate}
        finishDate={finishDate}
        practitionerId={selectedProvider}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        shouldFilter={shouldFilter}
        setFilter={setFilter}
      />
    </div>
  );
};

export default Billings;
