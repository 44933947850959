
import React from 'react';
import moment from "moment"

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const CurrentProviderTable = ({ data, navigate, defaultProfilePicture }) => {
  return (
    <table>
      <thead>
        <tr className="table-head">
          <th style={{ textAlign: 'start', fontFamily: 'Avenir' }}>Provider</th>
          <th style={{ textAlign: 'start', fontFamily: 'Avenir' }}>Email</th>
          <th style={{ fontFamily: 'Avenir' }}>APHRA Number</th>
          <th style={{ fontFamily: 'Avenir' }}>Provider Number</th>
          <th style={{ fontFamily: 'Avenir' }}>Provider Type</th>
         
          <th></th>
        </tr>
      </thead>
      <tbody>
        { Array.isArray(data) && data.map(practitioner=> (
          <tr className="table-row" key={practitioner?._id}>
            <td>
              <div className="custom-table-image">
                <img
                  src={defaultProfilePicture}
                  alt={practitioner?.firstName}
                  className=""
                />
                <p>{`${practitioner?.firstName || ''} ${practitioner?.lastName || ''}`}</p>
              </div>
            </td>
            <td>{`${practitioner?.email || 'N/A'}`}</td>
            <td>{`${practitioner?.registrationNumber || 'N/A'}`}</td>
            <td>{`${practitioner?.providerNumber || 'N/A'}`}</td>
            <td>{`${practitioner?.type || 'N/A'}`}</td>
         
           
       
            <td>
              
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CurrentProviderTable;
