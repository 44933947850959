import React, { useState, useEffect } from "react";
import styles from "./scheduleAppointment.module.scss";

import moment from "moment";
import { toast } from "react-toastify";
import { Steps, DatePicker, TimePicker } from "antd";
import CustomSelectDropDown from "../../CustomSelectDropDown/customSelectDropDown";
import Button from "../../Button";
import { api, appointmentApi } from "../../../api";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
const { Step } = Steps;
const { RangePicker } = DatePicker;

const RescheduleAppointment = ({ closeModal, appointmentData, setRefetch }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [appointmentDataFromApi, setAppointmentDataFromApi] = useState(
    appointmentData || {}
  );
  const [practices, setPractices] = useState([]);
  const [providers, setProviders] = useState([]);
  const [patients, setPatients] = useState([]);
  const [selectedPracticeId, setSelectedPracticeId] = useState(null);
  const [selectedPracticeProviderId, setSelectedPracticeProviderId] =
    useState(null);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [timeRange, setTimeRange] = useState([null, null]);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [submittingAppointment, setSubmittingAppointment] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const practiceId =
    user?.practice && user.practice.length > 0 ? user.practice[0].id : null;

  useEffect(() => {
    if (appointmentDataFromApi) {
      setSelectedPracticeId({
        label: appointmentDataFromApi.practice.name,
        value: appointmentDataFromApi.practice.id,
      });
      setSelectedPracticeProviderId({
        label: appointmentDataFromApi.provider.name,
        value: appointmentDataFromApi.provider.id,
      });
      setSelectedPatientId({
        label: appointmentDataFromApi.patient.name,
        value: appointmentDataFromApi.patient.id,
      });
      setTitle(appointmentDataFromApi.title);
      setDescription(appointmentDataFromApi.notes);
      // Assuming `appointmentDataFromApi` contains `startTime` and `endTime`

      // Extract the date from startTime or endTime (they should have the same date)
      setAppointmentDate(
        dayjs(dayjs(appointmentDataFromApi.startTime).format("YYYY-MM-DD"))
      );

      // Extract the time parts for the time range
      setTimeRange([
        dayjs(appointmentDataFromApi.startTime),
        dayjs(appointmentDataFromApi.endTime),
      ]);

      console.log(
        dayjs(appointmentDataFromApi.startTime).format("YYYY-MM-DD"),
        timeRange,
        "ststs"
      );
    }
  }, [appointmentDataFromApi]);

  useEffect(() => {
    const fetchPractices = async () => {
      try {
        const response = await api.get("/practices");
        setPractices(
          response.data.data.map((practice) => ({
            label: practice.name,
            value: practice.id,
          }))
        );
      } catch (error) {
        toast.error("Failed to fetch practices");
      }
    };

    fetchPractices();
  }, []);

  useEffect(() => {
    const fetchProvidersAndPatients = async () => {
      console.log(selectedPracticeId, "ss");
      if (selectedPracticeId) {
        try {
          const providersResponse = await api.get(
            `/practices/providers?practiceId=${selectedPracticeId.value}`
          );
          const patientsResponse = await api.get(
            `/practices/patients?practiceId=${selectedPracticeId.value}&kycStatus=approved`
          );

          setProviders(
            providersResponse.data.data.practitioners.map((provider) => ({
              label: `${provider.firstName} ${provider.lastName}`,
              value: provider._id,
            }))
          );

          setPatients(
            patientsResponse.data.data.patients.map((patient) => ({
              label: `${patient.firstName} ${patient.lastName}`,
              value: patient.id,
            }))
          );
        } catch (error) {
          toast.error("Failed to fetch providers or patients");
        }
      }
    };

    if (currentStep === 1 || appointmentDataFromApi) {
      fetchProvidersAndPatients();
    }
  }, [selectedPracticeId, currentStep]);

  const handleNext = () => {
    if (currentStep === 0 && !selectedPracticeId) {
      toast.error("Please select a practice to continue.");
      return;
    }
    if (
      currentStep === 1 &&
      (!selectedPracticeProviderId || !selectedPatientId)
    ) {
      toast.error("Please select a provider and a patient to continue.");
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const submitHandler = async () => {
    const startDateTime = dayjs(appointmentDate)
      .set("hour", dayjs(timeRange[0]).hour())
      .set("minute", dayjs(timeRange[0]).minute())
      .set("second", dayjs(timeRange[0]).second())
      .toDate();

    const endDateTime = dayjs(appointmentDate)
      .set("hour", dayjs(timeRange[1]).hour())
      .set("minute", dayjs(timeRange[1]).minute())
      .set("second", dayjs(timeRange[1]).second())
      .toDate();

    if (startDateTime >= endDateTime) {
      toast.error("Start time must be earlier than end time.");
      return;
    }

    setSubmittingAppointment(true);
    try {
      const payload = {
        patient: selectedPatientId.value,
        provider: selectedPracticeProviderId.value,
        practice: selectedPracticeId.value,
        startTime: startDateTime,
        endTime: endDateTime,
      };

      if (appointmentDataFromApi) {
        // Update existing appointment
        await appointmentApi.patch(
          `/appointments/${appointmentDataFromApi.id}`,
          payload
        );
        toast.success("Appointment updated successfully!");
      }
      setRefetch((val) => !val);
      closeModal();
    } catch (error) {
      if (error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error scheduling appointment");
      }
    } finally {
      setSubmittingAppointment(false);
    }
  };

  return (
    <div className={styles.rootx}>
      <div className={styles.time}>
        {`Today: ${moment(new Date()).utc().format("dddd, MMMM Do YYYY")}`}
      </div>
      <Steps current={currentStep}>
        <Step title="Practice" />
        <Step title="Provider and Patient" />
        <Step title="Appointment Details" />
      </Steps>
      <div className={styles.stepContent}>
        {currentStep === 0 && (
          <CustomSelectDropDown
            labelInValue={true}
            label="Select Practice"
            defaultValue={{
              label: appointmentDataFromApi?.practice?.name,
              value: appointmentDataFromApi?.practice?.id,
            }}
            value={selectedPracticeId}
            data={practices}
            onChange={setSelectedPracticeId}
          />
        )}
        {currentStep === 1 && (
          <>
            <CustomSelectDropDown
              labelInValue={true}
              label="Select Provider"
              data={providers}
              defaultValue={{
                label: appointmentDataFromApi?.provider?.name,
                value: appointmentDataFromApi?.provider?.id,
              }}
              onChange={(e) => setSelectedPracticeProviderId(e)}
            />
            <CustomSelectDropDown
              labelInValue={true}
              label="Select Patient"
              data={patients}
              defaultValue={{
                label: appointmentDataFromApi?.patient?.name,
                value: appointmentDataFromApi?.patient?.id,
              }}
              onChange={setSelectedPatientId}
            />
          </>
        )}
        {currentStep === 2 && (
          <>
            <div className={styles.dateContainer}>
              <div>
                <DatePicker
                  showToday={false}
                  style={{
                    height: "50px",
                    width: "100%",
                    marginBottom: "10px",
                  }}
                  format="YYYY-MM-DD"
                  value={appointmentDate}
                  onChange={(value) =>
                    setAppointmentDate(
                      value ? dayjs(value).startOf("day") : null
                    )
                  }
                />
              </div>
              <div>
                <TimePicker.RangePicker
                  use12Hours={true}
                  
                  value={timeRange}
                  style={{
                    height: "50px",
                    width: "100%",
                    marginBottom: "10px",
                  }}
                  format="h:mm A"
                  onChange={(values) => setTimeRange(values)}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.actions}>
        <div className={styles.actionsInnerContainer}>
          {currentStep > 0 && (
            <div className={styles.btnPrev}>
              <Button label="Previous" onClick={handlePrev} />
            </div>
          )}
          {currentStep < 2 && <Button label="Next" onClick={handleNext} />}
          {currentStep === 2 && (
            <Button
              label="Submit"
              isLoading={submittingAppointment}
              onClick={submitHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RescheduleAppointment;
