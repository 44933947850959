import React, { useState } from 'react';

import PlusIcon from '../../assets/icons/plus.svg';
import './appointmentProfile.scss';
import Modal from '../modal/modal';
import ScheduleAppointment from './scheduleAppointment/scheduleAppointment';

const ScheduleAppointmentComponent = ({
  setRefetch
}) => {
  const [openScheduleModal, setOpenScheduleModal] = useState(false);

  return (
    <>
      <div className="dashboard-profile">
        <div className="dashboard-profile-action">
          <div className="dashboard-profile-action-invite-allowed">
            <button
              onClick={() => setOpenScheduleModal(true)}
              className="dashboard-profile-invite"
            >
              <span>New Appointment</span>
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={openScheduleModal}
        onClose={() => setOpenScheduleModal(false)}
        children={< ScheduleAppointment  setRefetch={setRefetch} closeModal={setOpenScheduleModal} />}
      />
    </>
  );
};

export default ScheduleAppointmentComponent;
