import React from 'react';
import "./appointmentsTable.scss"
import ProviderCustomTable from './providerCustomTable';
import searchIcon from '../assets/icons/search.svg';
import { useState } from 'react';
import AppointmentCustomTable from './appointmentCustomTable';

const AppointmentTable = ({refetch, setRefetch}) => {
  const [searchInput, setSearchInput] = useState('');
  return (
    <div className="dashboard-users-table">
      <div className="dashboard-users-table-header">
        <div className="du-patient-list">
          <p>Appointments List</p>
          <div className="searchContainer">
            <img src={searchIcon} alt="" />
            <input
              type="search"
              placeholder="Search Appointments"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
        </div>
        <div className="du-view-all"></div>
      </div>
      <div>
        <AppointmentCustomTable searchInput={searchInput}  refetch={refetch} setRefetch={setRefetch}/>
      </div>
    </div>
  );
};
export default AppointmentTable;