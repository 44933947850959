import React, { useEffect, useState } from "react";
import "./providerCustomTable.scss";
import { useNavigate } from "react-router-dom";
import defaultProfilePicture from "../assets/logo/userAvatar.svg";

import { Skeleton } from "antd";
import CurrentProviderTable from "./Tables/currentProvider";

import { api, appointmentApi, fetcher } from "../api";

import PendingProviderTable from "./Tables/pendingprovider";
import useSWR from "swr";
import { useSelector } from "react-redux";
import CurrentAppointmentTable from "./Tables/currentAppointments";

const AppointmentCustomTable = ({
  refetch, setRefetch
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [appointments, setAppointments] = useState([]);
  const { user } = useSelector((state) => state.auth);
  

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("UPCOMING");
  const navigate = useNavigate();
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const practiceId =
    user?.practice && user.practice.length > 0 ? user.practice[0].id : null;

  const fetchData = async (status, page, limit) => {
    const statsMap = {
      UPCOMING:'Pending',
      COMPLETED:'Completed',
    }
    setLoading(true);
    try {
      const response = await appointmentApi.get(
        `/appointments?source=practice&status=${statsMap[status]}&page=${page}&limit=${limit}`
      );
      
      setAppointments(response?.data?.data?.appointments);
      setTotalPages(response.data.data?.totalPages || 1);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(activeTab, currentPage, 10);
  }, [currentPage, activeTab, practiceId, refetch]);



  const displayPageNumbers = () => {
    let pageNumbers = [];
    const maxPageNumbers = 8;

    for (
      let i = currentPage - 1;
      i > 0 && pageNumbers.length < maxPageNumbers / 2;
      i--
    ) {
      pageNumbers.unshift(i);
    }

    pageNumbers.push(currentPage);

    for (
      let i = currentPage + 1;
      i <= totalPages && pageNumbers.length < maxPageNumbers;
      i++
    ) {
      pageNumbers.push(i);
    }

    while (pageNumbers.length < maxPageNumbers && pageNumbers[0] > 1) {
      pageNumbers.unshift(pageNumbers[0] - 1);
    }

    while (
      pageNumbers.length < maxPageNumbers &&
      pageNumbers[pageNumbers.length - 1] < totalPages
    ) {
      pageNumbers.push(pageNumbers[pageNumbers.length - 1] + 1);
    }

    return pageNumbers;
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const displayPageNumber = (
    <div className="paginationContainer">
      <span
        className={`page-number ${currentPage === 1 ? "disabled" : ""}`}
        onClick={handlePrevPage}
      >
        &lt; Prev
      </span>

      {displayPageNumbers().map((pageNumber) => (
        <span
          key={pageNumber}
          className={`page-number ${
            pageNumber === currentPage ? "active" : ""
          }`}
          onClick={() => handlePageChange(pageNumber)}
        >
          {pageNumber}
        </span>
      ))}

      <span
        className={`page-number ${
          currentPage === totalPages ? "disabled" : ""
        }`}
        onClick={handleNextPage}
      >
        Next &gt;
      </span>
    </div>
  );

  return (
    <div className="custom-table">
      <div className="custom-table-top-tabs">
        <div className="custom-table-top-tabs-items">
          <span
            onClick={() => setActiveTab("UPCOMING")}
            className={activeTab === "UPCOMING" ? "tabs-active" : ""}
          >
            Upcoming
          </span>
          <span
            onClick={() => setActiveTab("COMPLETED")}
            className={activeTab === "COMPLETED" ? "tabs-active" : ""}
          >
            Completed
          </span>
        </div>
      </div>

      {loading ? (
        Array.from({ length: 5 }, (_, index) => (
          <div className="skeleton" key={index}>
            <Skeleton key={index} paragraph={false} active />
          </div>
        ))
      ) : (
        <>
          <CurrentAppointmentTable
            setRefetch={setRefetch}
            defaultProfilePicture={defaultProfilePicture}
            navigate={navigate}
            data={appointments || []}
          />
          <div className="paginationContainer">{displayPageNumber}</div>
        </>
      )}
    </div>
  );
};

export default AppointmentCustomTable;
