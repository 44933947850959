import React, { useState } from "react";
import { Select } from "antd";
import styles from "./customSelectDropDown.module.scss";

const { Option } = Select;

const CustomSelectDropdown = ({
  label,
  value,
  defaultValue,
  onChange,
  labelInValue = false, // Default to false
  name,
  showSearch = true,
  mode,
  data = [],
  onSearch,
  placeholder,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (onSearch) {
      onSearch(query);
    }
  };

  // Convert `value` to the appropriate format based on `labelInValue`
  const getValue = () => {
    if (!labelInValue || !value) return value; // Return `value` as-is when `labelInValue` is false
    if (typeof value === "object" && value.label && value.value) {
      return value; // If already formatted as { label, value }, return directly
    }
    // Find the matching option for `value` to construct the label
    const matchingOption = data.find((option) => option.value === value);
    return matchingOption
      ? { label: matchingOption.label, value: matchingOption.value }
      : value;
  };

  return (
    <div className={styles["ant-select-selector"]}>
      <label className={styles["custom-select-label"]}>{label}</label>
      <Select
        labelInValue={labelInValue}
        defaultValue={defaultValue}
        suffixIcon={null}
        aria-hidden="true"
        mode={mode}
        defaultActiveFirstOption={true}
        showSearch={showSearch}
        filterOption={false}
        style={{ width: "100%" }}
        value={getValue()}
        name={name}
        onChange={onChange}
        onSearch={handleSearch}
        placeholder={placeholder}
      >
        {data.map((item, index) => (
          <Option key={item?.id || index} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default CustomSelectDropdown;
