import React, { useState } from "react";
import moment from "moment";
import Modal from "../modal/modal";
import ScheduleAppointment from "../appointmentProfile/scheduleAppointment/scheduleAppointment";
import { appointmentApi } from "../../api";
import { toast } from "react-toastify";
import RescheduleAppointment from "../appointmentProfile/scheduleAppointment/rescheduleAppointment";

const CurrentAppointmentTable = ({
  data,
  defaultProfilePicture,

  setRefetch,
}) => {
 
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const handleReschedule = (appointment) => {
    setSelectedAppointment(appointment);
    setOpenRescheduleModal(true);
  };

  const handleCancel = async (appointmentId) => {
    try {
      const response = await appointmentApi.patch(
        `/appointments/cancel/${appointmentId}`
      );

      if (response.status === 200) {
        toast.success("Appointment canceled successfully!");
        setRefetch((val)=>!val);
      }
    } catch (error) {
      toast.error("Failed to cancel appointment");
     
    }
  };

  return (
    <>
      <table>
        <thead>
          <tr className="table-head">
            <th style={{ textAlign: "start", fontFamily: "Avenir" }}>
              Provider Name
            </th>
            <th style={{ fontFamily: "Avenir" }}>Patient Name</th>
            <th style={{ fontFamily: "Avenir" }}>Practice Name</th>
            <th style={{ fontFamily: "Avenir" }}>Start Time</th>
            <th style={{ fontFamily: "Avenir" }}>End Time</th>
            <th style={{ fontFamily: "Avenir" }}>Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) &&
            data.map((appointment) => (
              <tr className="table-row" key={appointment?.id}>
                <td>
                  <div className="custom-table-image">
                    <img
                      src={defaultProfilePicture}
                      alt={appointment?.provider?.name}
                    />
                    <td style={{ border: "none" }}>
                      {appointment?.provider?.name}
                    </td>
                  </div>
                </td>
                <td>{appointment?.patient?.name}</td>
                <td>{appointment?.practice?.name}</td>
                <td>{moment(appointment?.startTime).format("hh:mm A")}</td>
                <td>{moment(appointment?.endTime).format("hh:mm A")}</td>
                <td>{moment(appointment?.startTime).format("MMM DD, YYYY")}</td>
                <td>
                  <button
                    className="reschedule-button"
                    onClick={() => handleReschedule(appointment)}
                  >
                    Reschedule
                  </button>
                  <button
                    className="table-details-button"
                    onClick={() => handleCancel(appointment.id)}
                  >
                    Cancel
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
   
      {/* reschedule appointment */}
      <Modal
        isOpen={openRescheduleModal}
        onClose={() => setOpenRescheduleModal(false)}
        children={
          <RescheduleAppointment
            setRefetch={setRefetch}
            closeModal={setOpenRescheduleModal}
            appointmentData={selectedAppointment}
          />
        }
      />
    </>
  );
};

export default CurrentAppointmentTable;
