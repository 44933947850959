import React, { useState, useEffect } from "react";
import styles from "./scheduleAppointment.module.scss";

import moment from "moment";
import { toast } from "react-toastify";
import { Steps, Input, DatePicker, TimePicker } from "antd";
import CustomSelectDropDown from "../../CustomSelectDropDown/customSelectDropDown";
import Button from "../../Button";
import { api, appointmentApi } from "../../../api";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const { Step } = Steps;
const { RangePicker } = DatePicker;

const ScheduleAppointment = ({ closeModal, setRefetch }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [practices, setPractices] = useState([]);
  const [providers, setProviders] = useState([]);
  const [patients, setPatients] = useState([]);
  const [selectedPracticeId, setSelectedPracticeId] = useState("");
  const [selectedPracticeProviderId, setSelectedPracticeProviderId] =
    useState("");
  const [selectedPatientId, setSelectedPatientId] = useState("");
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [timeRange, setTimeRange] = useState([null, null]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [submittingAppointment, setSubmittingAppointment] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const practiceId =
    user?.practice && user.practice.length > 0 ? user.practice[0].id : null;

  useEffect(() => {
    const fetchPractices = async () => {
      try {
        const response = await api.get("/practices");
        if (Array.isArray(response.data.data)) {
          setPractices(
            response.data.data.map((practice) => ({
              label: practice.name,
              value: practice.id,
            }))
          );
        } else {
          throw new Error("Response is not an array");
        }
      } catch (error) {
        toast.error("Failed to fetch practices");
      }
    };

    fetchPractices();
  }, []);

  useEffect(() => {
    const fetchProvidersAndPatients = async () => {
      try {
        if (selectedPracticeId) {
          const providersResponse = await api.get(
            `/practices/providers?practiceId=${selectedPracticeId}`
          );
          const patientsResponse = await api.get(
            `/practices/patients?practiceId=${selectedPracticeId}&kycStatus=approved`
          );

          setProviders(
            providersResponse?.data?.data?.practitioners.map((provider) => ({
              label: `${provider.firstName} ${provider.lastName}`,
              value: provider._id,
            }))
          );

          setPatients(
            patientsResponse?.data?.data?.patients.map((patient) => ({
              label: `${patient.firstName} ${patient.lastName}`,
              value: patient.id,
            }))
          );
        }
      } catch (error) {
        toast.error("Failed to fetch providers or patients");
      }
    };

    if (currentStep === 1) {
      fetchProvidersAndPatients();
    }
  }, [selectedPracticeId, currentStep]);

  const handleNext = () => {
    if (currentStep === 0 && !selectedPracticeId) {
      toast.error("Please select a practice to continue.");
      return;
    }
    if (
      currentStep === 1 &&
      (!selectedPracticeProviderId || !selectedPatientId)
    ) {
      toast.error("Please select a provider and a patient to continue.");
      // return;
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const submitHandler = async () => {
    if (!appointmentDate || !timeRange[0] || !timeRange[1]) {
      toast.error("Please provide a valid date and time range.");
      return;
    }

    // Combine the date with the time range using Day.js
    const startDateTime = dayjs(appointmentDate)
      .set("hour", dayjs(timeRange[0]).hour())
      .set("minute", dayjs(timeRange[0]).minute())
      .set("second", dayjs(timeRange[0]).second())
      .toDate();

    const endDateTime = dayjs(appointmentDate)
      .set("hour", dayjs(timeRange[1]).hour())
      .set("minute", dayjs(timeRange[1]).minute())
      .set("second", dayjs(timeRange[1]).second())
      .toDate();

    if (startDateTime >= endDateTime) {
      toast.error("Start time must be earlier than end time.");
      return;
    }

    setSubmittingAppointment(true);

    try {
      await appointmentApi.post("/appointments", {
        patient: selectedPatientId,
        provider: selectedPracticeProviderId,
        practice: selectedPracticeId,
        startTime: startDateTime,
        endTime: endDateTime,
      });
      toast.success("Appointment scheduled successfully!");
      closeModal();
      setRefetch((val) => !val);
    } catch (error) {
      if (error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error scheduling appointment");
      } 
    } finally {
      setSubmittingAppointment(false);
    }
  };

  const handlePatientSearch = async (query) => {
    try {
      const response = await api.get(
        `/practices/patients?practiceId=${practiceId}&kycStatus=approved&q=${query}`
      );
      setPatients(
        response.data.data.patients.map((patient) => ({
          label: `${patient.firstName} ${patient.lastName}`,
          value: patient.id,
        }))
      );
    } catch (error) {
      toast.error("Error fetching patients");
    }
  };

  const handlePracticeSearch = async (query) => {
    try {
      const response = await api.get(`/practices&q=${query}`);
      if (Array.isArray(response.data.data)) {
        setPractices(
          response.data.data.map((practice) => ({
            label: practice.name,
            value: practice.id,
          }))
        );
      } else {
        throw new Error("Response is not an array");
      }
    } catch (error) {
      console.log(error);
      toast.error("Error searching for practice");
    }
  };

  const handleProviderSearch = async (query) => {
    try {
      const response = await api.get(
        `/practices/providers?practiceId=${selectedPracticeId}&query=${query}`
      );
      setProviders(
        response.data.data.practitioners.map((provider) => ({
          label: `${provider.firstName} ${provider.lastName}`,
          value: provider._id,
        }))
      );
    } catch (error) {
      toast.error("Error fetching providers");
    }
  };

  return (
    <div className={styles.rootx}>
      <div className={styles.time}>
        {`Today: ${moment(new Date()).utc().format("dddd, MMMM Do YYYY")}`}
      </div>
      <Steps current={currentStep}>
        <Step title="Practice" />
        <Step title="Provider and Patient" />
        <Step title="Appointment Details" />
      </Steps>
      <div className={styles.stepContent}>
        {currentStep === 0 && (
          <CustomSelectDropDown
            label="Select Practice"
            data={practices}
            value={selectedPracticeId}
            onChange={setSelectedPracticeId}
            onSearch={handlePracticeSearch}
          />
        )}
        {currentStep === 1 && (
          <>
            <CustomSelectDropDown
              label="Select Provider"
              data={providers}
              value={selectedPracticeProviderId}
              onChange={setSelectedPracticeProviderId}
              onSearch={handleProviderSearch}
            />
            <CustomSelectDropDown
              label="Select Patient"
              data={patients}
              value={selectedPatientId}
              onChange={setSelectedPatientId}
              onSearch={handlePatientSearch}
            />
          </>
        )}
        {currentStep === 2 && (
          <>
            <div className={styles.dateContainer}>
              <div>
                <DatePicker
                  showToday={false}
                  style={{
                    height: "50px",
                    width: "100%",
                    marginBottom: "10px",
                  }}
                  format="YYYY-MM-DD"
                  onChange={(value) =>
                    setAppointmentDate(
                      value ? dayjs(value).startOf("day") : null
                    )
                  }
                />
              </div>
              <div>
                <TimePicker.RangePicker
                  use12Hours={true}
                  showSecond={false}
                 minuteStep={1}
                 
                  style={{
                    height: "50px",
                    width: "100%",
                    marginBottom: "10px",
                  }}
                  format="h:mm A"
                  onChange={(values) => setTimeRange(values)}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.actions}>
        <div className={styles.actionsInnerContainer}>
          {currentStep > 0 && (
            <div className={styles.btnPrev}>
              <Button label="Previous" onClick={handlePrev} />
            </div>
          )}
          {currentStep < 2 && <Button label="Next" onClick={handleNext} />}
          {currentStep === 2 && (
            <Button
              label="Submit"
              isLoading={submittingAppointment}
              onClick={submitHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ScheduleAppointment;
