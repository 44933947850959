import { useState } from "react";
import ScheduleAppointmentComponent from '../components/appointmentProfile/appointmentProfile';
import AppointmentTable from '../components/appointmentsTable';
import "./appointments.scss";

const Appointments = () => {
  const [refetch, setRefetch] = useState(false);
  return (
    <div className="dashboard-container">
      <ScheduleAppointmentComponent refetch={refetch} setRefetch={setRefetch} />
      <AppointmentTable refetch={refetch} setRefetch={setRefetch} />
    </div>
  );
};

export default Appointments;
